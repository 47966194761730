import React, {useContext} from "react";
import {Box, Grid, Typography} from '@mui/material';
import PhotoGalleryBackground from '../../src/media/pictures/gigs/PhotoGalleryBackground.jpg';
import {Link} from "react-router-dom";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import {PhotoAlbumList} from "../config";
import {OgMetaTagsPages} from "./ReusableComponents/OgMetaTags";

export const PhotoGallery = ({ pageMeta }) => {

    const { isMobile, cardsPadding, isTablet } = useContext(LayoutContext);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: '100%',
                paddingBottom: isMobile ? 10 : 20,
                paddingTop: isMobile ? 7 : 17,
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${PhotoGalleryBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <OgMetaTagsPages content={pageMeta} />
            <Grid item large={11} xs={10} sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{color: '#fff'}}>Photos</Typography>
                <Typography variant="h5" sx={{color: '#fff'}}>Browse Camping In The Skies Galleries</Typography>
            </Grid>
            <Grid item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
            >
                {PhotoAlbumList.map((album, i) => (
                     album.socialMedia ? (
                            <Grid
                                item key={i}
                                sx={{ padding: cardsPadding,  cursor: 'pointer' }}
                                onClick={() => window.open(album.socialMedia, '_blank')}
                            >
                                <Box
                                    height={ isMobile ? "60vw" : 225}
                                    width={ isMobile ? "90vw" : 400}
                                    height={ isMobile ? "60vw" : 225}
                                    width={ isMobile ? "90vw" : 400}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column', // Center content vertically
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#1f1f1f',
                                        ':hover': {
                                            backgroundColor: '#262626', // Change to a brighter color on hover
                                        },
                                    }}
                                >
                                    <Typography variant="h5" sx={{ color: '#fff', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.7)', padding: '0px 5px', borderRadius: 2 }}>Follow us on Instagram</Typography>
                                    {React.cloneElement(album.icon, {size: 100, firstColor: '#1f1f1f', secondColor: '#ffffff'})}
                                </Box>
                            </Grid>
                        ) : (
                            <Grid item key={i} sx={{ padding: cardsPadding, }}>
                                <Link to={album.url} style={{ textDecoration: 'none' }}>
                                    <Box
                                        height={ isMobile ? "60vw" : 225}
                                        width={ isMobile ? "90vw" : 400}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column', // Center content vertically
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${album.albumCover})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            ':hover': {
                                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${album.albumCover})`,
                                            },
                                        }}
                                    >
                                        <Typography variant="h5" sx={{ color: '#fff', textAlign: 'center' }}>{album.albumName}</Typography>
                                    </Box>
                                </Link>
                            </Grid>
                        )
                ))}
            </Grid>
        </Grid>
    )
}
