import React, { useEffect, useState } from 'react';

export const CountdownToEvent = ({completeDate, backgroundColor, fontColor, labelsColor}) => {

    const styles = {
        flipClockDigit: {
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
            margin: "0 10px",
            fontSize: "2rem",
            lineHeight: 1
        },
        flipClockDigitInner: {
            height: "4rem",
            backgroundColor: backgroundColor? backgroundColor : "#000000",
            color: fontColor ? fontColor : "#fff",
            borderRadius: "5px",
            overflow: "hidden",
            width: "70px"
        },
        flipClockDigitSpan: {
            color: fontColor ? fontColor : "#ffffff",
            fontWeight: "bolder",
            lineHeight: "4rem",
            textAlign: "center",
            transition: "transform 0.5s ease-in-out"
        },
        flipClockLabel: {
            fontSize: "20px",
            color: labelsColor? labelsColor : "#000000",
            fontWeight: "bolder"
        }
    };

    const countdownDate = new Date(completeDate).getTime();
    const [timeDigits, setTimeDigits] = useState(['0', '0', '0', '0']);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const timeRemaining = countdownDate - now;
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24)).toString().padStart(2, '0').split('');
            const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0').split('');
            const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0').split('');
            const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000).toString().padStart(2, '0').split('');

            setTimeDigits([days[0], days[1], hours[0], hours[1], minutes[0], minutes[1], seconds[0], seconds[1]]);
        }, 1000);

        return () => clearInterval(interval);
    }, [countdownDate]);

    return (
        <div
            className="flip-clock"
             style={{
                fontFamily: "Copperplate, 'Copperplate Gothic Light', sans-serif",
                fontWeight: "bolder",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
             }}>
            <div className="flip-clock__days" style={styles.flipClockDigit}>
                <div className="flip-clock__digit" style={styles.flipClockDigitInner}>
                    <span className="flip-clock__digit--upper" style={styles.flipClockDigitSpan}>{timeDigits[0]}</span>
                    <span className="flip-clock__digit--lower" style={styles.flipClockDigitSpan}>{timeDigits[1]}</span>
                </div>
                <span className="flip-clock__label" style={styles.flipClockLabel}>Days</span>
            </div>
            <div className="flip-clock__hours" style={styles.flipClockDigit}>
                <div className="flip-clock__digit" style={styles.flipClockDigitInner}>
                    <span className="flip-clock__digit--upper" style={styles.flipClockDigitSpan}>{timeDigits[2]}</span>
                    <span className="flip-clock__digit--lower" style={styles.flipClockDigitSpan}>{timeDigits[3]}</span>
                </div>
                <span className="flip-clock__label" style={styles.flipClockLabel}>Hours</span>
            </div>
            <div className="flip-clock__minutes" style={styles.flipClockDigit}>
                <div className="flip-clock__digit" style={styles.flipClockDigitInner}>
                    <span className="flip-clock__digit--upper" style={styles.flipClockDigitSpan}>{timeDigits[4]}</span>
                    <span className="flip-clock__digit--lower" style={styles.flipClockDigitSpan}>{timeDigits[5]}</span>
                </div>
                <span className="flip-clock__label" style={styles.flipClockLabel}>Mins</span>
            </div>
            <div className="flip-clock__seconds" style={styles.flipClockDigit}>
                <div className="flip-clock__digit" style={styles.flipClockDigitInner}>
                    <span className="flip-clock__digit--upper" style={styles.flipClockDigitSpan}>{timeDigits[6]}</span>
                    <span className="flip-clock__digit--lower" style={styles.flipClockDigitSpan}>{timeDigits[7]}</span>
                </div>
                <span className="flip-clock__label" style={styles.flipClockLabel}>Secs</span>
            </div>
        </div>
    );
};

//https://www.google.com/maps/dir/?api=1&destination=207+Cowgate+Edinburgh+EH1+1JQ%+United+Kingdom