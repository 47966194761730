import React, {Fragment, useContext} from "react";
import {Box} from "@mui/system";
import Stramash13March25 from "../../media/pictures/gigs/Stramash13March25.gig.png";
import {CountdownToEvent} from "../CountdownToEvent/CountdownToEvent";
import {Button} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {Link} from "react-router-dom";
import {PagesList} from "../../config";
import {LayoutContext} from "../../contexts/LayoutContext/LayoutContext";

export const GigEvent = () => {

    const { isMobile } = useContext(LayoutContext);
    const goToVenueFromOwnLocation = 'https://www.google.com/maps/dir/?api=1&destination=207+Cowgate+Edinburgh+EH1+1JQ%+United+Kingdom';
    const countDownDate = 'March 13, 2025 20:00:00';
    const facebookEventPage = 'https://www.facebook.com/events/644088021454688?acontext=%7B%22event_action_history%22%3A[]%7D'

    return(
        <Fragment>
            <Box sx={{
                width: isMobile? '100%' : '55%',
                height: isMobile? '100vw' : '55vw',
                alignItems: 'center',
                justifyContent: 'center',
                top:  isMobile ? 70 : 23,
                left: 0,
                backgroundImage: `url(${Stramash13March25})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: "flex",
                // filter: 'grayscale(100%)',
                zIndex: 1,
            }}>
                <Box
                    display={ isMobile ? 'none' : 'flex' }
                    flexDirection="column"
                    marginTop={50}
                    gap={2}
                    alignItems="center"
                    backgroundColor={'rgba(210,172,107,0.40)'}
                    padding={1}
                    borderRadius={2}
                >
                    <CountdownToEvent completeDate={countDownDate} backgroundColor={'#fff'} fontColor={'#000000'} labelsColor={"#000000"}/>
                    <Box width="95%" display="flex" flexDirection="row" fontSize={10} gap={1}>
                        <Button
                            sx={{
                                backgroundColor: '#fff',
                                color: '#000000',
                                height: 60,
                                width: '50%',
                                '&:hover': {
                                    color: '#fff',
                                    backgroundColor: '#d2ac6b', // Change the highlight color on hover
                                },
                                '&:focus': {
                                    color: '#fff',
                                    backgroundColor: '#d2ac6b', // Change the highlight color on focus
                                },
                            }}
                            onClick={ () => window.open( goToVenueFromOwnLocation, '_blank')}
                            variant="outlined"
                        >
                            <LocationOnIcon/>
                            Get there
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: '#fff',
                                color: '#000000',
                                height: 60,
                                width: '50%',
                                '&:hover': {
                                    color: '#fff',
                                    backgroundColor: '#d2ac6b', // Change the highlight color on hover
                                },
                                '&:focus': {
                                    color: '#fff',
                                    backgroundColor: '#d2ac6b', // Change the highlight color on focus
                                },
                            }}
                        >
                            <Link
                                to={PagesList.videos.route}
                                style={{textDecoration: 'none', color: '#000000',}}
                            >
                                Videos
                            </Link>
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: '#fff',
                                color: '#000000',
                                height: 60,
                                width: '50%',
                                '&:hover': {
                                    color: '#fff',
                                    backgroundColor: '#d2ac6b', // Change the highlight color on hover
                                },
                                '&:focus': {
                                    color: '#fff',
                                    backgroundColor: '#d2ac6b', // Change the highlight color on focus
                                },
                            }}
                            onClick={ () => window.open( facebookEventPage, '_blank')}
                            variant="outlined"
                        >
                            Facebook Event
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box display={ isMobile ? 'flex' : 'none' } flexDirection="column" alignItems="center" margin="10px 0px" gap={1}>
                <CountdownToEvent completeDate={countDownDate} backgroundColor={'#000000'} fontColor={'#fff'} />
                <Box width="95%" display="flex" flexDirection="row" fontSize={10} gap={1}>
                    <Button
                        sx={{
                            backgroundColor: '#000000',
                            color: '#fff',
                            height: 60,
                            width: '50%',
                            '&:hover': {
                                color: '#fff',
                                backgroundColor: '#d2ac6b', // Change the highlight color on hover
                            },
                            '&:focus': {
                                color: '#fff',
                                backgroundColor: '#d2ac6b', // Change the highlight color on focus
                            },
                        }}
                        onClick={ () => window.open(goToVenueFromOwnLocation, '_blank')}
                        variant="outlined"
                    >
                        <LocationOnIcon/>
                        Get there
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            backgroundColor: '#000000',
                            color: '#fff',
                            height: 60,
                            width: '50%',
                            '&:hover': {
                                color: '#fff',
                                backgroundColor: '#d2ac6b', // Change the highlight color on hover
                            },
                            '&:focus': {
                                color: '#fff',
                                backgroundColor: '#d2ac6b', // Change the highlight color on focus
                            },
                        }}
                    >
                        <Link
                            to={PagesList.videos.route}
                            style={{textDecoration: 'none', color: '#fff',}}
                        >
                            Videos
                        </Link>
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: '#000000',
                            color: '#fff',
                            height: 60,
                            width: '50%',
                            '&:hover': {
                                color: '#fff',
                                backgroundColor: '#d2ac6b', // Change the highlight color on hover
                            },
                            '&:focus': {
                                color: '#fff',
                                backgroundColor: '#d2ac6b', // Change the highlight color on focus
                            },
                        }}
                        onClick={ () => window.open(facebookEventPage, '_blank')}
                        variant="outlined"
                    >
                        Facebook Event
                    </Button>
                </Box>
            </Box>
        </Fragment>
    )
}