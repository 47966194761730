import IMG1 from '../Homebar-november-2024/102-30Nov24Cits-2A5A1462-Enhanced-NR.jpg'
import IMG2 from '../Homebar-november-2024/125-30Nov24Cits-2A5A1485-Enhanced-NR.jpg'
import IMG3 from '../Homebar-november-2024/129-30Nov24Cits-2A5A1489-Enhanced-NR.jpg'
import IMG4 from '../Homebar-november-2024/132-30Nov24Cits-2A5A1492-Enhanced-NR.jpg'
import IMG5 from '../Homebar-november-2024/134-30Nov24Cits-2A5A1494-Enhanced-NR.jpg'
import IMG6 from '../Homebar-november-2024/144-30Nov24Cits-2A5A1504-Enhanced-NR.jpg'
import IMG7 from '../Homebar-november-2024/145-30Nov24Cits-2A5A1505-Enhanced-NR.jpg'
import IMG8 from '../Homebar-november-2024/151-30Nov24Cits-2A5A1511-Enhanced-NR.jpg'
import IMG9 from '../Homebar-november-2024/163-30Nov24Cits-2A5A1523-Enhanced-NR.jpg'
import IMG10 from '../Homebar-november-2024/165-30Nov24Cits-2A5A1525-Enhanced-NR.jpg'
import IMG11 from '../Homebar-november-2024/168-30Nov24Cits-2A5A1528-Enhanced-NR.jpg'
import IMG12 from '../Homebar-november-2024/172-30Nov24Cits-2A5A1532-Enhanced-NR.jpg'
import IMG13 from '../Homebar-november-2024/173-30Nov24Cits-2A5A1533-Enhanced-NR.jpg'
import IMG14 from '../Homebar-november-2024/189-30Nov24Cits-2A5A1549-Enhanced-NR.jpg'
import IMG15 from '../Homebar-november-2024/194-30Nov24Cits-2A5A1554-Enhanced-NR.jpg'
import IMG16 from '../Homebar-november-2024/212-30Nov24Cits-2A5A1572-Enhanced-NR.jpg'
import IMG17 from '../Homebar-november-2024/229-30Nov24Cits-2A5A1589-Enhanced-NR.jpg'
import IMG18 from '../Homebar-november-2024/235-30Nov24Cits-2A5A1595-Enhanced-NR.jpg'
import IMG19 from '../Homebar-november-2024/238-30Nov24Cits-2A5A1598.jpg'
import IMG20 from '../Homebar-november-2024/246-30Nov24Cits-2A5A1606.jpg'
import IMG21 from '../Homebar-november-2024/255-30Nov24Cits-2A5A1615-Enhanced-NR.jpg'
import IMG22 from '../Homebar-november-2024/270-30Nov24Cits-2A5A1630-Enhanced-NR.jpg'
import IMG23 from '../Homebar-november-2024/28-30Nov24Cits-2A5A1797-Enhanced-NR-3.jpg'
import IMG24 from '../Homebar-november-2024/286-30Nov24Cits-2A5A1646-Enhanced-NR.jpg'
import IMG25 from '../Homebar-november-2024/294-30Nov24Cits-2A5A1654.jpg'
import IMG26 from '../Homebar-november-2024/302-30Nov24Cits-2A5A1662-Enhanced-NR.jpg'
import IMG27 from '../Homebar-november-2024/304-30Nov24Cits-2A5A1664.jpg'
import IMG28 from '../Homebar-november-2024/328-30Nov24Cits-2A5A1688-Enhanced-NR.jpg'
import IMG29 from '../Homebar-november-2024/333-30Nov24Cits-2A5A1693-Enhanced-NR.jpg'
import IMG30 from '../Homebar-november-2024/340-30Nov24Cits-2A5A1700-Enhanced-NR.jpg'
import IMG31 from '../Homebar-november-2024/350-30Nov24Cits-2A5A1710-Enhanced-NR.jpg'
import IMG32 from '../Homebar-november-2024/352-30Nov24Cits-2A5A1712-Enhanced-NR-1.jpg'
import IMG33 from '../Homebar-november-2024/357-30Nov24Cits-2A5A1717-Enhanced-NR.jpg'
import IMG34 from '../Homebar-november-2024/378-30Nov24Cits-2A5A1738.jpg'
import IMG35 from '../Homebar-november-2024/380-30Nov24Cits-2A5A1740.jpg'
import IMG36 from '../Homebar-november-2024/382-30Nov24Cits-2A5A1742-Enhanced-NR.jpg'
import IMG37 from '../Homebar-november-2024/385-30Nov24Cits-2A5A1745-Enhanced-NR-1.jpg'
import IMG38 from '../Homebar-november-2024/421-30Nov24Cits-2A5A1781-Enhanced-NR.jpg'
import IMG39 from '../Homebar-november-2024/432-30Nov24Cits-2A5A1792-Enhanced-NR.jpg'
import IMG40 from '../Homebar-november-2024/436-30Nov24Cits-2A5A1796-Enhanced-NR.jpg'
import IMG41 from '../Homebar-november-2024/51-30Nov24Cits-2A5A1411-Enhanced-NR.jpg'
import IMG42 from '../Homebar-november-2024/52-30Nov24Cits-2A5A1412-Enhanced-NR.jpg'
import IMG43 from '../Homebar-november-2024/538-30Nov24Cits-SAM_0753.jpg'
import IMG44 from '../Homebar-november-2024/539-30Nov24Cits-SAM_0754.jpg'
import IMG45 from '../Homebar-november-2024/540-30Nov24Cits-SAM_0755.jpg'
import IMG46 from '../Homebar-november-2024/541-30Nov24Cits-SAM_0756.jpg'
import IMG47 from '../Homebar-november-2024/542-30Nov24Cits-SAM_0757.jpg'
import IMG48 from '../Homebar-november-2024/84-30Nov24Cits-2A5A1444-Enhanced-NR-1.jpg'
import IMG49 from '../Homebar-november-2024/96-30Nov24Cits-2A5A1456-Enhanced-NR.jpg'
import IMG50 from '../Homebar-november-2024/IMG_1808.jpg'
import IMG51 from '../Homebar-november-2024/IMG_1822-Enhanced-NR.jpg'


export const HomebarGigNovember2024List = [
    IMG1,
    IMG2,
    IMG3,
    IMG4,
    IMG5,
    IMG6,
    IMG7,
    IMG8,
    IMG9,
    IMG10,
    IMG11,
    IMG12,
    IMG13,
    IMG14,
    IMG15,
    IMG16,
    IMG17,
    IMG18,
    IMG19,
    IMG20,
    IMG21,
    IMG22,
    IMG23,
    IMG24,
    IMG25,
    IMG26,
    IMG27,
    IMG28,
    IMG29,
    IMG30,
    IMG31,
    IMG32,
    IMG33,
    IMG34,
    IMG35,
    IMG36,
    IMG37,
    IMG38,
    IMG39,
    IMG40,
    IMG41,
    IMG42,
    IMG43,
    IMG44,
    IMG45,
    IMG46,
    IMG47,
    IMG48,
    IMG49,
    IMG50,
    IMG51
]



















































